import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Styled from './index.styled';
import Button from '../Button';
import LinkStandard from '../LinkStandard';
import Browser from '../../../lib/browser';

const getTitleStyle = type => {
	switch (type) {
		case 'primary':
			return Styled.AmenitiesTitlePrimary;
		case 'secondary':
			return Styled.AmenitiesTitleSecondary;
		default:
			return Styled.AmenitiesTitlePrimary;
	}
};

const AmenitiesTitle = ({
	anchorId,
	heading,
	copy,
	richCopy,
	titleType = 'primary',
	center = false,
	columnMode,
	linkText,
	linkUrl,
	linkIcon,
	linkMode,
}) => {
	const TitleStyle = getTitleStyle(titleType);

	return (
		<TitleStyle as="section" center={center} id={anchorId || ''}>
			<Styled.AmentitiesTitle__Inner columnMode={columnMode}>
				<Styled.AmenitiesTitle__Heading as="h2" center={center}>
					{heading}
				</Styled.AmenitiesTitle__Heading>
				<Styled.AmenitiesTitle__Copy center={center}>{copy}</Styled.AmenitiesTitle__Copy>
				<Styled.AmenitiesTitle__RichCopy center={center} as="div">
					{documentToReactComponents(richCopy, {
						renderText: text =>
							text.split('\n').flatMap((textCopy, i) => [i > 0 && <br />, textCopy]),
					})}
				</Styled.AmenitiesTitle__RichCopy>
				{(linkText || '') !== '' && (linkUrl || '') !== '' && (
					<Styled.AmenitiesTitle__Link center={center}>
						{linkMode === 'Button' && (
							<Button
								buttonLink={linkUrl}
								buttonText={linkText}
								iconName={linkIcon}
								callback={event => Browser.handleClick(event, linkUrl)}
							/>
						)}
						{linkMode === 'Link' && <LinkStandard url={linkUrl} title={linkText} />}
					</Styled.AmenitiesTitle__Link>
				)}
			</Styled.AmentitiesTitle__Inner>
		</TitleStyle>
	);
};

export default AmenitiesTitle;
