import styled, { css } from 'styled-components';
import { GridFullStretch, Grid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { margins, gap } from '../../../styles/settings/spacing';
import { weight } from '../../../styles/settings/typography';
import { above } from '../../../styles/tools/media';
import { smBodyLight, xlTitleRegular } from '../Typography/index.styled';

const AmenitiesTitlePrimary = styled(Grid)`
    margin-top: ${gap[48]};
    margin-bottom: ${gap[32]};

    ${above(
        breakpoints[960],
        () => css`
            margin-top: ${gap[80]};
            margin-bottom: ${gap[56]};
        `
    )}

    ${above(
        breakpoints[1440],
        () => css`
            margin-top: ${gap[80]};
        `
    )}

	${above(
        breakpoints[1920],
        () => css`
            row-gap: 0;
        `
    )}
`;

const AmenitiesTitleSecondary = styled(GridFullStretch)`
    padding-top: ${gap[48]};
    background-color: var(--secondaryBackground);

    ${above(
        breakpoints[960],
        () => css`
            padding-top: ${gap[80]};
        `
    )}

    ${above(
        breakpoints[1440],
        () => css`
            padding-top: ${gap[80]};
        `
    )}
`;

const AmentitiesTitle__Inner = styled.div`
    grid-column: 1 / -1;

    ${(props) =>
        props.columnMode === 'Center' &&
        css`
            ${above(
                breakpoints[960],
                () => css`
                    grid-column: 4 / span 6;
                `
            )}
        `}
`;

const AmenitiesTitle__Heading = styled(xlTitleRegular)`
    color: var(--primaryForeground);
    grid-column: 1 / -1;
    margin-bottom: ${gap[24]};

    ${above(
        breakpoints[960],
        () => css`
            margin-bottom: ${gap[32]};

            ${(props) =>
                props.center &&
                css`
                    text-align: center;
                `}
        `
    )}
`;

const AmenitiesTitle__Copy = styled(smBodyLight)`
    max-width: 720px;
    color: var(--black);
    grid-column: 1 / -1;

    ${above(
        breakpoints[960],
        () => css`
            ${(props) =>
                props.center &&
                css`
                    max-width: none;
                    text-align: center;
                    grid-column: 4 / span 6;
                `}
        `
    )}
`;

const AmenitiesTitle__RichCopy = styled(smBodyLight)`
	color: var(--black);
	grid-column: 1 / -1;

	li > p {
		margin-bottom: 0px;
	}
	& p {
		margin-bottom: ${margins[24]};
	}

	& ul {
			list-style-type: square;
			padding-left: ${gap[24]};
		}
	
	& ol {
			padding-left: ${gap[24]};
		}
	
	& li::marker {
			color: var(--primaryAccent);
		}

		& h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: ${weight[400]};
			margin-bottom: ${gap[12]};
		}

		${above(
            breakpoints[1280],
            () => css`
                margin-bottom: ${margins[32]};
            `
        )}
	}
	${above(
        breakpoints[960],
        () => css`
            ${(props) =>
                props.center &&
                css`
                    max-width: none;
                    text-align: center;
                    grid-column: 4 / span 6;
                `}
        `
    )}
`;

const AmenitiesTitle__Link = styled.div`
    ${above(
        breakpoints[960],
        () => css`
            ${(props) =>
                props.center &&
                css`
                    text-align: center;
                `}
        `
    )}
`;

export default {
    AmenitiesTitlePrimary,
    AmenitiesTitleSecondary,
    AmentitiesTitle__Inner,
    AmenitiesTitle__Heading,
    AmenitiesTitle__Copy,
    AmenitiesTitle__RichCopy,
    AmenitiesTitle__Link,
};
